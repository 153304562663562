import queryString from 'query-string';
import { ELEPAY_URL } from '../configs/config';
import { useLocation } from 'react-router-dom';
import { useMenuContext } from '../context/MenuContext';

const usePaymentMethod = () => {
  const url = `${ELEPAY_URL}codes`;
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { stadiumData } = useMenuContext();

  const cleanNumberString = (value: string): number => {
    const cleanedValue = value.replace(/[^\d]/g, '');
    return parseInt(cleanedValue, 10);
  };

  const filteredQueryParams = {
    seatID: queryParams.seatID,
    stadiumID: queryParams.stadiumID,
  };

  const payment = async (amount: string, orderNo: string, location_id: string, merchant_id: string) => {
    try {
      const orderId = `${orderNo}_` + new Date().getTime();
      const hostPath = window.location.origin;
      const orderDetailLink = queryString.stringifyUrl({
        url: `/orderdetail/${orderNo}`,
        query: filteredQueryParams,
      });

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic ' + btoa(stadiumData.elepay_secret_key),
        },
        body: JSON.stringify({
          amount: cleanNumberString(amount),
          orderNo: orderId,
          description: orderNo,
          locationId: location_id,
          metadata: {
            merchant_id: merchant_id,
          },
          frontUrl: `${hostPath}${orderDetailLink}`,
        }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error ', error);
    }
  };

  return {
    payment,
  };
};

export default usePaymentMethod;
