import React from 'react';

const Loader: React.FC = () => {
  const skeletonCategories = Array.from({ length: 1 }, (_, index) => index + 1);
  const skeletonFoods = Array.from({ length: 3 }, (_, index) => index + 1);

  return (
    <div className="min-h-screen flex w-full justify-center bg-black-0">
      <div className="w-full max-w-2xl bg-white h-full min-h-screen">
        <div className={'fixed w-full max-w-2xl z-[3] animate-pulse'}>
          <div className="h-[140px] bg-gray-200 p-4">
            <div className="bg-black-0 opacity-60 rounded-lg text-white h-9 w-9 flex justify-center items-center"></div>
          </div>
          <div className="p-4 flex flex-col gap-1">
            <>
              <div className="text-gray-800 text-2xl not-italic leading-8 font-semibold h-8 bg-gray-200"></div>
              <div className="text-gray-500 text-xs font-medium not-italic leading-[18px] flex gap-2">
                <div className="h-4 w-[10%] bg-gray-200 rounded"></div>
                <div className="h-4 w-[10%] bg-gray-200 rounded"></div>
              </div>
              <div className="flex gap-1 flex-wrap">
                <div className="h-4 w-[25%] bg-gray-200 rounded"></div>
                <div className="h-4 w-[25%] bg-gray-200 rounded"></div>
                <div className="h-4 w-[25%] bg-gray-200 rounded"></div>
              </div>
            </>
          </div>
        </div>

        <div className={`h-[280px] relative z-[5] animate-pulse`}>
          <div className="flex fixed w-full max-w-2xl gap-2 items-center px-4 shadow-search">
            <button className="p-3"></button>
            <p className="text-gray-700 text-base font-medium leading-6"></p>
          </div>
        </div>

        <div className={`flex sticky z-[5] top-[44px] bg-white border-t w-full overflow-scroll no-scrollbar gap-1 py-2 px-4 border-gray-200 shadow-search animate-pulse`}>
          <div className="w-fit py-4 px-8 text-sm font-semibold leading-5 whitespace-nowrap rounded-md bg-gray-200"></div>
          <div className="w-fit py-4 px-8 text-sm font-semibold leading-5 whitespace-nowrap rounded-md bg-gray-200"></div>
        </div>

        <div className={`flex-1 overflow-y-auto p-4 relative z-[4] bg-white animate-pulse`}>
          {skeletonCategories.map((category, index) => (
            <div key={index}>
              <h2 className="text-gray-500 text-base not-italic font-medium leading-6 pt-4 bg-gray-200 animate-pulse"></h2>
              <div className="grid grid-cols-1 gap-4">
                {skeletonFoods.map((food, index) => (
                  <div key={index} className="py-4 border-b border-gray-200 bg-gray-200 animate-pulse">
                    <div className="w-full flex items-end justify-between gap-2">
                      <div className="flex items-center">
                        <div className="rounded-lg w-[100px] h-[100px] bg-gray-300"></div>
                        <div className="flex flex-col gap-1 overflow-hidden ml-4 max-w-[72%]">
                          <div className="h-[24px] w-[150px] bg-gray-300"></div>
                          <div className="h-[24px] w-[100px] bg-gray-300"></div>
                          <div className="bg-gray-300 h-[24px] w-[24px]"></div>
                        </div>
                      </div>
                      <div className="flex gap-3 items-center">
                        <div className="flex gap-1 rounded-lg py-1 px-2 border border-neutral-300 items-center h-[24px] w-[24px] bg-gray-300"></div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Loader;
