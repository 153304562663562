import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from '../../components/Button';
import ClearIcon from '@mui/icons-material/Clear';
import { useOrderContext } from '../../context/OrderContext';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import CircularProgress from '@mui/material/CircularProgress';
import { useMenuContext } from '../../context/MenuContext';
import WelcomeLoader from './Loader';

interface WelcomeProps {}

const Welcome: React.FC<WelcomeProps> = () => {
  const [loadingTimeout, setLoadingTimeout] = useState(true);
  const location = useLocation();
  const { updateSeatId } = useOrderContext();
  const { seatInformation, setStadiumID, setSeatId } = useMenuContext();
  const { t } = useTranslation();
  const queryParams = queryString.parse(location.search);
  const seatID = queryParams.seatID as string;
  const stadiumID = queryParams.stadiumID as string;
  const orderId = sessionStorage.getItem('orderId');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoadingTimeout(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const updateSeatIdAsync = async () => {
      if (seatID && stadiumID) {
        sessionStorage.setItem('stadiumID', stadiumID);
        sessionStorage.setItem('seatId', seatID);
        setStadiumID(stadiumID);
        setSeatId(seatID);
        try {
          await updateSeatId(seatID);
        } catch (error) {
          return;
        }
      }
    };

    updateSeatIdAsync();
  }, [seatID, stadiumID]);

  if (!seatID && loadingTimeout) {
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <CircularProgress color="success" size={65} />
      </div>
    );
  }

  if (!seatID) {
    return <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">Wrong seat or stadium</div>;
  }

  const newOrderLink = queryString.stringifyUrl({
    url: `/neworder/${orderId}`,
    query: queryParams,
  });

  const trackOrderLink = queryString.stringifyUrl({
    url: `/neworder/${orderId}`,
    query: { ...queryParams, tab: 'orderHistory' },
  });

  return seatInformation && seatInformation.seat_number !== '' && seatInformation.seat_type !== '' ? (
    <div className="bg-black-1 h-full w-full px-4 flex items-center justify-center">
      <div className="bg-white px-4 py-5 w-full max-w-2xl rounded-xl shadow-modalWhite">
        <div className="flex gap-1 flex-col pb-6">
          <div className="flex justify-between">
            <p className="text-lg font-semibold leading-7 not-italic text-black-900">{t('WELCOME_TITLE')}</p>
            <Link to={newOrderLink}>
              <button>
                <ClearIcon />
              </button>
            </Link>
          </div>
          <p className="text-lg font-semibold leading-7 not-italic text-black-900">{seatInformation?.seat_type}</p>
          <p className="text-lg font-semibold leading-7 not-italic text-black-900">{seatInformation?.seat_number}</p>
          <p className="text-sm not-italic font-normal leading-5 text-black-600"> {t('WELCOME_DESCRIPTION')}</p>
        </div>

        <div className="flex flex-col gap-3">
          <Link to={newOrderLink}>
            <Button type="secondary">{t('WELCOME_BUTTON_NEWORDER')}</Button>
          </Link>
          <Link to={trackOrderLink}>
            <Button type="primary">{t('WELCOME_BUTTON_TRACKORDER')}</Button>
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <WelcomeLoader />
  );
};

export default Welcome;
