let DB_TABLE_ALL_CATEGORIES = 'stadium_manage_all_categories';
let DB_TABLE_ALL_FOOD_DETAILS = 'stadium_manage_all_food_details';
let DB_TABLE_ALL_ORDERS = 'stadium_manage_all_orders';
let DB_TABLE_ALL_SERVICE_REQUESTS = 'meny_manage_all_service_requests';
let DB_TABLE_ADVERTISEMENT_CONFIG = 'meny_manage_advertisement_config';
let DB_TABLE_ADVERTISEMENT_INFO = 'meny_manage_advertisement_info';
let DB_TABLE_RESTAURANT_CONFIG = 'meny_manage_restaurant_config';
let DB_TABLE_USAGE_STATISTICS = 'meny_manage_usage_statistics';

let DB_TABLE_USER_SETTINGS = 'meny_manage_user_settings';
let DB_TABLE_MENY_TRANSLATION = 'meny_translation';

const RESTAURANT_OPEN_STATE_OPENED = 0;
const RESTAURANT_OPEN_STATE_PREPARING = 1;

export default {
  DB_TABLE_ADVERTISEMENT_CONFIG,
  DB_TABLE_ADVERTISEMENT_INFO,
  DB_TABLE_ALL_FOOD_DETAILS,
  DB_TABLE_ALL_CATEGORIES,
  DB_TABLE_ALL_ORDERS,
  DB_TABLE_ALL_SERVICE_REQUESTS,
  DB_TABLE_RESTAURANT_CONFIG,
  DB_TABLE_USER_SETTINGS,
  DB_TABLE_USAGE_STATISTICS,
  DB_TABLE_MENY_TRANSLATION,
  RESTAURANT_OPEN_STATE_OPENED,
  RESTAURANT_OPEN_STATE_PREPARING,
};
